@tailwind base;
@tailwind components;
@tailwind utilities;

ul:not([class]) {
  list-style-type: disc; /* Adds bullet points */
  padding-left: 20px; /* Adjust the padding as needed for indentation */
}

.quiz {
  width: 100%;
  height: 100%;
  position: fixed;
  padding: 1rem 0 0 0;
}
.quiz-footer {
  position: fixed;
  bottom: 0;
  height: 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.screen-container {
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
}

.screen {
  /* flex: 1;
  padding: 20px;
  opacity: 0; */
  display: none !important;
}

.screen.active {
  display: block !important;
  opacity: 1;
}

/* .screen > h2 {
  margin-bottom: 20px;
}

.screen > input {
    display: block;
    width: 100%;
    margin: 20px 0;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */

/* .img-button {
  display: flex;
  align-items: center;
  padding: 3px 7px;
  background-color: var(--tertiary);
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  max-width: 200px;
}

.img-button img {
  margin-right: 5px;
  width: 40px;
}

.img-button .text {
  flex: 1;
  line-height: 1.2;
} */

.image-checkbox {
  width: 120px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

/*.image-checkbox img {*/
/*  width: 40px; !* Adjust the image size as needed *!*/
/*  height: 40px; !* Adjust the image size as needed *!*/
/*  border: 2px solid #ccc;*/
/*  border-radius: 7px;*/
/*}*/

.image-checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.image-checkbox div {
  font-size: 14px;
}

.dob {
}
.dob > label {
  margin: 3px 5px;
}

.dob > label:nth-child(2) {
  padding: 0 30px;
}

.dob > input {
  width: 70px;
  margin: 0 5px;
  border-radius: 50px;
  padding: 4px 8px;
}

.weight {
}
.weight > label {
  margin: 3px 15px;
}

.weight > input {
  width: 70px;
  margin: 0 5px;
  margin: 0 15px;
  border-radius: 50px;
  padding: 4px 8px;
}

#summary {
}

.screen-checkbox label {
  cursor: pointer;
}

.button-grid {
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); Adjust the button width as needed */
  /* grid-gap: 10px; Adjust the gap between buttons as needed */
}

.checkbox-grid {
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); 
  grid-gap: 10px; */
}

.screen > .grid-button {
  /* border: none;
  padding: 10px;
  text-align: center;
  cursor: pointer;*/
}

.flag-icon {
  height: 1.3rem;
  width: 1.3rem;

  position: relative;
  display: inline-block;
  line-height: 1em;
  cursor: pointer;

  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon-us {
  background-image: url(assets/fonts/flag-icon/us.svg);
}

.flag-icon-de {
  background-image: url(assets/fonts/flag-icon/de.svg);
}

.slick-dots li button:before {
  content: "";
}

.slick-dots li {
  margin: 0 5px;
  width: 25px;
  height: 25px;
  border: 2px solid var(--tertiary);

  border-radius: 50%;
}

/* Style for active (selected) dot */
.slick-dots li.slick-active {
  background-color: var(--tertiary);
}

.hide-scrollbar::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.hide-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.slick-dots {
  li {
    height: 10px !important;
    width: 10px !important;
    border-radius: 100% !important;
    /* display: none !important; */
    button::before {
      display: none !important;
    }
    button::after {
      display: none !important;
    }
  }
}

.new-accordion {
  p {
    margin: 0 !important;
    padding: 10px 5px !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.spinner_V8m1 {
  transform-origin: center;
  animation: spinner_zKoa 2s linear infinite;
}
.spinner_V8m1 circle {
  stroke-linecap: round;
  animation: spinner_YpZS 1.5s ease-in-out infinite;
}
@keyframes spinner_zKoa {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner_YpZS {
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }
  47.5% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -16;
  }
  95%,
  100% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -59;
  }
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

html,
body {
  overflow-x: hidden;
}
