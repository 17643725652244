.faq-inner .accordion-header {
  font-size: 26px;
  font-weight: 600;
  color: var(--text-58595b);
  padding: 10px;
  margin-left: 10px;
}
.faq-inner h5 {
  color: var(--text-6d6e71);
  font-weight: normal;
}
.faq-inner .accordion .accordion-item {
  border-radius: 50px;
  margin-bottom: 15px;
  border: 1px solid var(--bg-d1d3d4) !important;
}
.faq-inner .accordion .accordion-item .accordion-header button {
  border-radius: 50px;
}
.faq-inner .accordion .accordion-item .accordion-header button:focus {
  box-shadow: none;
}

.faq-inner .accordion .accordion-button img {
  margin-right: 15px;
}
.faq-inner .accordion .accordion-button {
  font-size: 22px;
  text-transform: uppercase;
  color: var(--text-6d6e71);
  padding: 10px 20px;
}
.faq-inner .accordion .accordion-button:not(.collapsed) {
  color: var(--text-6d6e71);
  background-color: transparent;
  box-shadow: unset;
}
.faq-inner .accordion-button::after {
  width: 30px;
  height: 30px;
  background-size: 30px;
}

.faq-inner .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23005d67'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  color: var(--text-6d6e71);
}
.faq-inner p {
  color: var(--text-58595b);
  padding: 20px;
}
.faq-inner .accordion .accordion-item {
  width: 100%;
}

/****faq home page***********/
.faq-home .accordion-header {
  font-size: 10px;
  font-weight: 600;
  color: var(--text-white);
  /* padding: 5px; */
  margin-left: 10px;
}
.faq-home h5 {
  color: var(--text-6d6e71);
  font-weight: normal;
}
.faq-home .accordion .accordion-item {
  border-radius: 50px;
  margin-bottom: 15px;
  border: 1px solid var(--bg-d1d3d4) !important;
}
.faq-home .accordion .accordion-item .accordion-header button {
  border-radius: 50px;
}
.faq-home .accordion .accordion-item .accordion-header button:focus {
  box-shadow: none;
}

.faq-home .accordion .accordion-button img {
  margin-right: 15px;
}
.faq-home .accordion .accordion-button {
  font-size: 22px;
  text-transform: uppercase;
  color: var(--text-6d6e71);
  padding: 10px 20px;
}
.faq-home .accordion .accordion-button:not(.collapsed) {
  color: var(--text-6d6e71);
  background-color: transparent;
  box-shadow: unset;
}
.faq-home .accordion-button::after {
  width: 30px;
  height: 30px;
  background-size: 30px;
}

.faq-home .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23005d67'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  color: var(--text-6d6e71);
}
.faq-home p {
  color: var(--text-58595b);
  padding: 20px;
}
.faq-home .accordion .accordion-item {
  width: 100%;
}

.faq .accordion .accordion-item .accordion-header button {
  color: white;
}
.faq .accordion .accordion-item {
  max-width: 600px;
}
.faq .accordion .accordion-item .accordion-header button img:nth-of-type(1) {
  height: 50px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-right: 30px;
}

.faq .accordion .accordion-item .accordion-body {
  padding: 0px 0px 30px 15px;
  font-weight: 200;
}

.faq .accordion .accordion-item .accordion-body p {
  margin-bottom: 0;
  color: var(--bs-card-color);
}

/***** login *******************/

.login-section {
  padding: 90px 0;
  background: linear-gradient(var(--secondary-80), var(--secondary-80)),
    url(../images/home/bg-login.webp);
  background-size: cover;
  background-position: center;
}

.login-section .textboxes .fa {
  position: absolute;
  left: 40px;
  top: 0px;
  bottom: 0;
  margin: auto;
  color: var(--text-939598);
  margin-top: 14px;
  font-size: 20px;
  color: #969595;
}
.login-section .form-control {
  padding: 8px 8px 8px 60px;
  border-radius: 50px;
  font-size: 20px;
}
.login-section textarea + .fa {
  margin: unset;
  top: 22px;
}
.login-section .form-control::placeholder {
  color: var(--text-939598);
  font-size: 18px;
  font-weight: 500;
  /* text-transform: uppercase; */
}
.login-section a {
  color: white;
}
.text-right {
  float: right;
}
.text-right-align {
  text-align: right;
}
.mlogo {
  text-align: center;
}
.textboxes {
  text-align: center;
}
.text-head {
  text-align: center;
  color: white;
  font-size: 18px;
}

.login-section .btn-custom-secondary:hover {
  color: #c9c9c9;
  background-color: var(--secondary);
}

/***** quiz *******************/

.quiz {
  background-size: cover;
  background-position: center;
  overflow-x: auto;
  padding: 10px 0 60px;
}
.screen {
  margin-bottom: 65px;
}
.quiz h2 {
  text-align: center;
  color: white;
  font-size: 26px;
}
.quiz .textboxes .fa {
  position: absolute;
  left: 40px;
  top: 0px;
  bottom: 0;
  margin: auto;
  color: var(--text-939598);
  font-size: 30px;
  margin-top: 8px;
  color: #424242;
}
.quiz .form-control {
  padding: 8px 15px 8px 30px;
  border-radius: 50px;
  font-size: 20px;
}
.quiz .form-control::placeholder {
  color: var(--text-939598);
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}
.quiz .btn-custom-secondary {
  background-color: var(--quaternary);
}

.quiz .btn:hover {
  background-color: white;
  color: var(--text-58595b);
}
.quiz .btn:focus {
  background-color: white;
  color: var(--text-58595b);
}

.screen label.checked {
  background-color: var(--quaternary);
}

.screen label.checked div,
label {
  color: #fff;
}

/**** slide1 */
/*.slide1 {width: 50%;}*/
.screen-checkbox label {
  color: white;
  font-size: 14px;
  margin-top: 25px;
}
.screen-checkbox input[type="checkbox"] {
  height: 15px;
  width: 15px;
  border-radius: 10%;
  margin-right: 10px;
}
.screen h2 {
  margin-top: 45px;
  margin-bottom: 45px;
}
.screen .btn-custom-secondary {
  margin-top: 45px;
}

/**** slide2 */

#gSupplements .img-button,
#gFrequently .img-button,
.slide5 .img-button,
.slide6 .img-button,
#gLifestyle .img-button,
#gEat-fish .img-button,
#gEat-meat .img-button,
#gGrains .img-button,
#gFruit-vege .img-button,
#gDairy-products .img-button,
#gEat-out .img-button,
#gAlcoholic-drinks .img-button,
#gCoffee .img-button,
#gCoffee-alt .img-button,
#gSmoke .img-button,
#gExposed-sun .img-button,
#gSedentary .img-button {
  text-align: center;
  background-color: var(--bg-efeff0);
  border-radius: 5%;
  border: none;
  margin: 7px;
}

#gFrequently .img-button img,
#gSupplements .img-button img,
#gEat-fish .img-button img,
#gEat-meat .img-button img,
#gGrains .img-button img,
#gFruit-vege .img-button img,
#gDairy-products .img-button img,
#gEat-out .img-button img,
#gAlcoholic-drinks .img-button img,
#gCoffee .img-button img,
#gCoffee-alt .img-button img,
#gSmoke .img-button img,
#gExposed-sun .img-button img,
#gSedentary .img-button img,
.slide5 .img-button img,
.slide10 .img-button img,
.slide24 .image-checkbox img,
.slide26 .image-checkbox img,
.slide27 .image-checkbox img,
.slide28 .image-checkbox img,
.slide30 .image-checkbox img,
.slide33 .image-checkbox img,
.slide34 .image-checkbox img,
.slide35 .image-checkbox img,
#gLifestyle .img-button img,
.slide37 .image-checkbox img,
.slide51 .image-checkbox img {
  max-width: 45px;
  display: inline;
  line-break: strict;
}

.slide11 .image-checkbox,
.slide17 .image-checkbox,
.slide24 .image-checkbox,
.slide26 .image-checkbox,
.slide27 .image-checkbox,
.slide28 .image-checkbox,
.slide30 .image-checkbox,
.slide33 .image-checkbox,
.slide34 .image-checkbox,
.slide35 .image-checkbox,
.slide37 .image-checkbox,
.slide51 .image-checkbox,
.slide52 .image-checkbox,
.slide54 .image-checkbox,
.slide55 .image-checkbox {
  text-align: center;
  background-color: var(--bg-efeff0);
  border-radius: 5%;
  border: none;
  margin: 7px;
}

.slide11 .image-checkbox img,
.slide17 .image-checkbox img,
.slide52 .image-checkbox img,
.slide54 .image-checkbox img,
.slide55 .image-checkbox img {
  max-width: 35px;
  line-break: strict;
}

.slide11 .image-checkbox div,
.slide17 .image-checkbox div,
.slide24 .image-checkbox div,
.slide26 .image-checkbox div,
.slide27 .image-checkbox div,
.slide28 .image-checkbox div,
.slide30 .image-checkbox div,
.slide33 .image-checkbox div,
.slide34 .image-checkbox div,
.slide35 .image-checkbox div,
.slide37 .image-checkbox div,
.slide51 .image-checkbox div,
.slide52 .image-checkbox div,
.slide54 .image-checkbox div,
.slide55 .image-checkbox div {
  color: var(--text-58595b);
  font-size: 16px;
  display: block;
  margin-top: 4px;
  margin-bottom: 4px;
}

.slide10 button,
.slide13 button,
.slide14 button,
.slide19 button,
.slide22 button,
.slide23 button,
.slide50 button {
  text-align: center;
  background-color: var(--bg-efeff0);
  border-radius: 5%;
  border: none;
  margin: 7px;
  vertical-align: middle;
  position: relative;
}

.slide15 .btn,
.slide16 .btn,
.slide18 .btn,
.slide20 .btn,
.slide21 .btn,
.slide25 .btn,
.slide27 .btn,
.slide30 .btn,
.slide31 .btn,
.slide34 .btn,
.slide35 .btn {
  width: 48%;
  margin-left: 5px;
  text-align: center;
}

.slide3 .btn,
.slide29 .btn,
.slide53 .btn {
  width: 23%;
  margin-left: 5px;
}

.slide3 .btn:hover,
.slide15 .btn:hover,
.slide16 .btn:hover,
.slide18 .btn:hover,
.slide20 .btn:hover,
.slide21 .btn:hover,
.slide25 .btn:hover,
.slide29 .btn:hover {
  background-color: white;
  color: var(--text-58595b);
}

.slide3 .btn:focus,
.slide15 .btn:focus,
.slide16 .btn:focus,
.slide18 .btn:focus,
.slide20 .btn:focus,
.slide21 .btn:focus,
.slide25 .btn:focus,
.slide29 .btn:focus {
  background-color: white;
  color: var(--text-58595b);
}

.img-button .text {
  color: var(--text-58595b);
  font-size: 16px;
  display: block;
  margin-top: 2px;
}
.img-button .text br {
  line-height: 35px;
}
.img-button:hover .text {
  color: white;
}
.img-button:focus .text {
  color: white;
}
.img-button:hover {
  background-color: var(--quaternary) !important;
}
.img-button:focus {
  background-color: var(--quaternary) !important;
}

#gSupplements .img-button {
  padding: 40px;
  width: 25%;
}
/**** slide3 */

/**** slide4 */
#gFrequently .img-button {
  padding: 20px;
  width: 20%;
  overflow: hidden;
}

/**** slide5 */
.slide5 .img-button {
  width: 25%;
  height: 100px;
}
.slide5 .img-button div {
  color: var(--text-58595b);
  font-size: 16px;
  display: block;
}
.slide5 .img-button:hover div {
  color: white;
}
.slide5 .img-button:focus div {
  color: white;
}

.slide6 .img-button {
  width: 25%;
  height: 100px;
}
.slide6 .img-button div {
  color: var(--text-58595b);
  font-size: 16px;
  display: block;
}
.slide6 .img-button:hover div {
  color: white;
}
.slide6 .img-button:focus div {
  color: white;
}

.slide7 div .btn {
  width: 50%;
  margin-top: 50px;
}

.slide8 .btn {
  width: 50%;
  margin-top: 125px;
}
.slide7 label,
.slide8 label {
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
  margin-top: 10px;
}

.slide9 .form-control {
  font-size: 16px;
}

.slide10 button {
  padding: 20px;
  width: 20%;
  height: 75%;
}
.slide10 button div {
  color: var(--text-58595b);
  font-size: 15px;
  display: block;
}
.slide10 button:hover {
  background-color: var(--quaternary);
}
.slide10 button:focus {
  background-color: var(--quaternary);
}
.slide10 button:hover div {
  color: white;
}
.slide10 button:focus div {
  color: white;
}
.slide10 button div br {
  line-height: 35px;
}
.slide10 button div::first-line {
  font-weight: 600;
}

.slide11 .image-checkbox {
  padding: 10px;
  width: 17%;
  height: 30%;
}
.slide11 label:focus {
  background-color: var(--quaternary);
}
.slide11 .image-checkbox:focus div {
  color: white;
}

.slide11 .btn {
  width: 40%;
  text-align: center;
}

.slide12 label {
  color: white;
  margin-bottom: 25px;
}

.slide13 button {
  padding: 20px;
  width: 20%;
  height: 50%;
}
.slide13 button div {
  color: var(--text-58595b);
  font-size: 15px;
  display: block;
}
.slide13 button:hover {
  background-color: var(--quaternary);
}
.slide13 button:focus {
  background-color: var(--quaternary);
}
.slide13 button:hover div {
  color: white;
}
.slide13 button:focus div {
  color: white;
}
.slide13 button div br {
  line-height: 35px;
}

.slide14 button {
  padding: 20px;
  width: 20%;
  height: 50%;
}
.slide14 button div {
  color: var(--text-58595b);
  font-size: 15px;
  display: block;
}
.slide14 button:hover {
  background-color: var(--quaternary);
}
.slide14 button:focus {
  background-color: var(--quaternary);
}
.slide14 button:hover div {
  color: white;
}
.slide14 button:focus div {
  color: white;
}
.slide14 button div br {
  line-height: 35px;
}

.slide17 .image-checkbox {
  padding: 10px;
  width: 22%;
  height: 30%;
}
/*.slide17 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide17 label:focus {
  background-color: var(--quaternary);
}
/*.slide17 .image-checkbox:hover div { color: white; }*/
.slide17 .image-checkbox:focus div {
  color: white;
}
.slide17 .btn {
  width: 50%;
  text-align: center;
}

.slide19 button {
  padding: 20px;
  width: 23%;
  height: 50%;
}
.slide19 button div {
  color: var(--text-58595b);
  font-size: 15px;
  display: block;
}
.slide19 button:hover {
  background-color: var(--quaternary);
}
.slide19 button:focus {
  background-color: var(--quaternary);
}
.slide19 button:hover div {
  color: white;
}
.slide19 button:focus div {
  color: white;
}
.slide19 button div br {
  line-height: 35px;
}

.slide22 button {
  padding: 20px;
  width: 23%;
  height: 38%;
}
.slide22 button div {
  color: var(--text-58595b);
  font-size: 15px;
  display: block;
}
.slide22 button:hover {
  background-color: var(--quaternary);
}
.slide22 button:focus {
  background-color: var(--quaternary);
}
.slide22 button:hover div {
  color: white;
}
.slide22 button:focus div {
  color: white;
}
.slide22 button div br {
  line-height: 35px;
}

.slide23 button {
  padding: 20px;
  width: 20%;
  height: 40%;
}
.slide23 button div {
  color: var(--text-58595b);
  font-size: 15px;
  display: block;
}
.slide23 button:hover {
  background-color: var(--quaternary);
}
.slide23 button:focus {
  background-color: var(--quaternary);
}
.slide23 button:hover div {
  color: white;
}
.slide23 button:focus div {
  color: white;
}
.slide23 button div br {
  line-height: 35px;
}

.slide24 .image-checkbox {
  padding: 10px;
  width: 22%;
  height: 100%;
}
/*.slide24 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide24 label:focus {
  background-color: var(--quaternary);
}
/*.slide24 .image-checkbox:hover div { color: white; }*/
.slide24 .image-checkbox:focus div {
  color: white;
}
.slide24 .btn {
  width: 50%;
  text-align: center;
}

.slide26 .image-checkbox {
  padding: 10px;
  width: 23%;
  height: 35%;
}
/*.slide26 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide26 label:focus {
  background-color: var(--quaternary);
}
/*.slide26 .image-checkbox:hover div { color: white; }*/
.slide26 .image-checkbox:focus div {
  color: white;
}
.slide26 .btn {
  width: 50%;
  text-align: center;
}

.slide27 .image-checkbox {
  padding: 10px;
  width: 23%;
  height: 25%;
}
/*.slide27 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide27 label:focus {
  background-color: var(--quaternary);
}
/*.slide27 .image-checkbox:hover div { color: white; }*/
.slide27 .image-checkbox:focus div {
  color: white;
}

.slide28 .image-checkbox {
  padding: 10px;
  width: 22%;
  height: 35%;
}
/*.slide28 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide28 label:focus {
  background-color: var(--quaternary);
}
/*.slide28 .image-checkbox:hover div { color: white; }*/
.slide28 .image-checkbox:focus div {
  color: white;
}
.slide28 .btn {
  width: 50%;
  text-align: center;
}

.slide30 .image-checkbox {
  padding: 10px;
  width: 22%;
  height: 35%;
}
/*.slide30 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide30 label:focus {
  background-color: var(--quaternary);
}
/*.slide30 .image-checkbox:hover div { color: white; }*/
.slide30 .image-checkbox:focus div {
  color: white;
}

.slide32 .btn {
  width: 28%;
  margin-left: 5px;
  text-align: center;
}

.slide33 .image-checkbox {
  padding: 20px;
  width: 25%;
  height: 30%;
  overflow: hidden;
}
/*.slide33 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide33 label:focus {
  background-color: var(--quaternary);
}
/*.slide33 .image-checkbox:hover div { color: white; }*/
.slide33 .image-checkbox:focus div {
  color: white;
}
.slide33 .btn {
  width: 50%;
  margin-left: 5px;
  text-align: center;
}
.slide33 .btn {
  margin-top: 45px;
}

.slide34 .image-checkbox {
  padding: 10px;
  width: 22%;
  height: 35%;
}
/*.slide34 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide34 label:focus {
  background-color: var(--quaternary);
}
/*.slide34 .image-checkbox:hover div { color: white; }*/
.slide34 .image-checkbox:focus div {
  color: white;
}

.slide35 .image-checkbox {
  padding: 10px;
  width: 31%;
  height: 30%;
  overflow: hidden;
}
/*.slide35 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide35 label:focus {
  background-color: var(--quaternary);
}
/*.slide35 .image-checkbox:hover div { color: white; }*/
.slide35 .image-checkbox:focus div {
  color: white;
}

#gLifestyle .img-button {
  padding: 20px;
  width: 28%;
}

.slide37 .image-checkbox {
  padding: 20px;
  width: 32%;
  height: 33%;
}
/*.slide37 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide37 label:focus {
  background-color: var(--quaternary);
}
/*.slide37 .image-checkbox:hover div { color: white; }*/
.slide37 .image-checkbox:focus div {
  color: white;
}
.slide37 .btn {
  width: 40%;
  margin-left: 5px;
  text-align: center;
}
.slide37 .btn {
  margin-top: 45px;
}

#gEat-fish .img-button {
  padding: 20px;
  width: 28%;
}

#gEat-meat .img-button {
  padding: 20px;
  width: 28%;
}

#gGrains .img-button {
  padding: 20px;
  width: 28%;
}

#gFruit-vege .img-button {
  padding: 20px;
  width: 22%;
}

#gDairy-products .img-button {
  padding: 20px;
  width: 28%;
}

#gEat-out .img-button {
  padding: 20px;
  width: 28%;
}

#gAlcoholic-drinks .img-button {
  padding: 20px;
  width: 28%;
}

#gCoffee .img-button {
  padding: 20px;
  width: 28%;
}

#gCoffee-alt .img-button {
  padding: 20px;
  width: 28%;
  margin: 7px;
}

#gSmoke .img-button {
  padding: 20px;
  width: 28%;
}

#gExposed-sun .img-button {
  padding: 20px;
  width: 28%;
}

#gSedentary .img-button {
  padding: 20px;
  width: 28%;
}

.slide50 button {
  padding: 20px;
  width: 30%;
  height: 60%;
}
.slide50 button div {
  color: var(--text-58595b);
  font-size: 15px;
  display: block;
}
.slide50 button:hover {
  background-color: var(--quaternary);
}
.slide50 button:focus {
  background-color: var(--quaternary);
}
.slide50 button:hover div {
  color: white;
}
.slide50 button:focus div {
  color: white;
}
.slide50 button div br {
  line-height: 35px;
}

.slide51 .image-checkbox {
  padding: 20px;
  width: 36%;
  height: 33%;
}
/*.slide51 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide51 label:focus {
  background-color: var(--quaternary);
}
/*.slide51 .image-checkbox:hover div { color: white; }*/
.slide51 .image-checkbox:focus div {
  color: white;
}
.slide51 .btn {
  width: 40%;
  margin-left: 5px;
  text-align: center;
}
.slide51 .btn {
  margin-top: 100px;
}

.slide52 .image-checkbox {
  padding: 20px;
  width: 20%;
  height: 33%;
}
/*.slide52 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide52 label:focus {
  background-color: var(--quaternary);
}
/*.slide52 .image-checkbox:hover div { color: white; }*/
.slide52 .image-checkbox:focus div {
  color: white;
}
.slide52 .btn {
  width: 40%;
  margin-left: 5px;
  text-align: center;
}
.slide52 .btn {
  margin-top: 100px;
}

.slide54 .image-checkbox {
  padding: 20px;
  width: 22%;
  height: 33%;
}
/*.slide54 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide54 label:focus {
  background-color: var(--quaternary);
}
/*.slide54 .image-checkbox:hover div { color: white; }*/
.slide54 .image-checkbox:focus div {
  color: white;
}
.slide54 .btn {
  width: 40%;
  margin-left: 5px;
  text-align: center;
}
.slide54 .btn {
  margin-top: 100px;
}

.slide55 .image-checkbox {
  padding: 20px;
  width: 22%;
  height: 33%;
}
/*.slide55 .image-checkbox:hover { background-color: var(--quaternary); cursor: pointer; }*/
.slide55 label:focus {
  background-color: var(--quaternary);
}
/*.slide55 .image-checkbox:hover div { color: white; }*/
.slide55 .image-checkbox:focus div {
  color: white;
}
.slide55 .btn {
  width: 40%;
  margin-left: 5px;
  text-align: center;
}
.slide55 .btn {
  margin-top: 100px;
}

@media only screen and (min-width: 769px) {
  .slide11 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide17 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide24 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide26 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide27 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide28 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide30 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide33 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide34 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide35 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide37 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide51 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide52 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide54 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }
  .slide55 .image-checkbox:hover {
    background-color: var(--quaternary);
    cursor: pointer;
  }

  .slide11 .image-checkbox:hover div {
    color: white;
  }
  .slide17 .image-checkbox:hover div {
    color: white;
  }
  .slide24 .image-checkbox:hover div {
    color: white;
  }
  .slide26 .image-checkbox:hover div {
    color: white;
  }
  .slide27 .image-checkbox:hover div {
    color: white;
  }
  .slide28 .image-checkbox:hover div {
    color: white;
  }
  .slide30 .image-checkbox:hover div {
    color: white;
  }
  .slide33 .image-checkbox:hover div {
    color: white;
  }
  .slide34 .image-checkbox:hover div {
    color: white;
  }
  .slide35 .image-checkbox:hover div {
    color: white;
  }
  .slide37 .image-checkbox:hover div {
    color: white;
  }
  .slide51 .image-checkbox:hover div {
    color: white;
  }
  .slide52 .image-checkbox:hover div {
    color: white;
  }
  .slide54 .image-checkbox:hover div {
    color: white;
  }
  .slide55 .image-checkbox:hover div {
    color: white;
  }
}

@media (max-width: 768px) {
  .slide11 label:focus {
    background-color: unset;
  }
  .slide11 .image-checkbox:focus div {
    color: black;
  }

  .slide17 label:focus {
    background-color: unset;
  }
  .slide17 .image-checkbox:focus div {
    color: black;
  }

  .slide24 label:focus {
    background-color: unset;
  }
  .slide24 .image-checkbox:focus div {
    color: black;
  }

  .slide26 label:focus {
    background-color: unset;
  }
  .slide26 .image-checkbox:focus div {
    color: black;
  }

  .slide27 label:focus {
    background-color: unset;
  }
  .slide27 .image-checkbox:focus div {
    color: black;
  }

  .slide28 label:focus {
    background-color: unset;
  }
  .slide28 .image-checkbox:focus div {
    color: black;
  }

  .slide30 label:focus {
    background-color: unset;
  }
  .slide30 .image-checkbox:focus div {
    color: black;
  }

  .slide33 label:focus {
    background-color: unset;
  }
  .slide33 .image-checkbox:focus div {
    color: black;
  }

  .slide34 label:focus {
    background-color: unset;
  }
  .slide34 .image-checkbox:focus div {
    color: black;
  }

  .slide35 label:focus {
    background-color: unset;
  }
  .slide35 .image-checkbox:focus div {
    color: black;
  }

  .slide37 label:focus {
    background-color: unset;
  }
  .slide37 .image-checkbox:focus div {
    color: black;
  }

  .slide51 label:focus {
    background-color: unset;
  }
  .slide51 .image-checkbox:focus div {
    color: black;
  }

  .slide52 label:focus {
    background-color: unset;
  }
  .slide52 .image-checkbox:focus div {
    color: black;
  }

  .slide54 label {
    vertical-align: top;
  }
  .slide54 label:focus {
    background-color: unset;
  }
  .slide54 .image-checkbox:focus div {
    color: black;
  }

  .slide55 label {
    vertical-align: top;
  }
  .slide55 label:focus {
    background-color: unset;
  }
  .slide55 .image-checkbox:focus div {
    color: black;
  }
}

@media (max-width: 1200px) {
  .slide37 .image-checkbox {
    padding: 5px;
    width: 40%;
    height: 35%;
    margin: 7px;
  }
  #gAlcoholic-drinks .img-button {
    padding: 20px;
    width: 35%;
  }
  #gExposed-sun .img-button {
    padding: 20px;
    width: 35%;
  }
  .slide51 .image-checkbox {
    padding: 5px;
    width: 40%;
    height: 35%;
    margin: 7px;
    overflow: hidden;
  }
  .slide54 .image-checkbox {
    padding: 5px;
    width: 40%;
    height: 35%;
    margin: 7px;
    overflow: hidden;
  }
  .slide54 .image-checkbox {
    padding: 20px;
    width: 22%;
    height: 33%;
    overflow: hidden;
  }
}
@media (max-width: 1024px) {
  #gSupplements .img-button {
    padding: 20px;
    width: 45%;
    overflow: hidden;
  }
  #gFrequently .img-button {
    padding: 20px;
    width: 40%;
    overflow: hidden;
  }

  .slide10 button {
    padding: 5px;
  }
  .slide10 button {
    padding: 20px;
    width: 33%;
    height: 35%;
  }

  .slide11 .image-checkbox {
    padding: 5px;
    width: 22%;
    height: 20%;
    margin: 7px;
    vertical-align: middle;
  }
  .slide14 button {
    padding: 5px;
    width: 23%;
    height: 50%;
  }
  .slide17 .image-checkbox {
    padding: 5px;
    width: 23%;
    height: 32%;
    margin: 7px;
    vertical-align: middle;
  }
  .slide19 button {
    padding: 5px;
    width: 23%;
    height: 50%;
  }
  .slide22 button {
    padding: 5px;
    width: 30%;
    height: 30%;
  }
  .slide23 button {
    padding: 5px;
    width: 25%;
  }
  .slide24 .image-checkbox {
    padding: 10px;
    width: 23%;
    height: 50%;
    margin: 7px;
  }
  .slide26 .image-checkbox {
    padding: 10px;
    width: 23%;
    height: 40%;
    margin: 7px;
  }
  .slide27 .image-checkbox {
    padding: 10px;
    width: 30%;
    height: 50%;
    margin: 7px;
  }
  .slide33 .image-checkbox {
    padding: 5px;
    width: 31%;
    height: 30%;
    margin: 7px;
    overflow: hidden;
  }
  .slide34 .image-checkbox {
    padding: 10px;
    width: 31%;
    height: 35%;
    margin: 7px;
  }
  .slide35 .image-checkbox {
    padding: 10px;
    width: 31%;
    height: 30%;
    margin: 7px;
  }
  #gLifestyle .img-button {
    padding: 20px;
    width: 35%;
  }
  .slide37 .image-checkbox {
    padding: 5px;
    width: 46%;
    height: 35%;
    margin: 7px;
    overflow: hidden;
  }

  #gFruit-vege .img-button {
    padding: 20px;
    width: 35%;
  }
  #gAlcoholic-drinks .img-button {
    padding: 20px;
    width: 45%;
  }

  #gSmoke .img-button {
    padding: 20px;
    width: 31%;
  }

  #gExposed-sun .img-button {
    padding: 20px;
    width: 31%;
  }

  .slide52 .image-checkbox {
    width: 20%;
    height: 30%;
    overflow: hidden;
  }
  .slide54 .image-checkbox {
    padding: 20px;
    width: 25%;
    height: 20%;
    overflow: hidden;
  }
  .slide55 .image-checkbox {
    padding: 20px;
    width: 30%;
    height: 25%;
    overflow: hidden;
  }
  .slide55 .btn {
    margin-top: 125px;
  }
}

@media (max-width: 768px) {
  .slide1 .screen {
    width: 80%;
  }

  .slide3 .btn,
  .slide15 .btn,
  .slide16 .btn,
  .slide18 .btn {
    width: 40%;
    margin-left: 5px;
    text-align: center;
  }

  .slide20 .btn,
  .slide21 .btn,
  .slide25 .btn,
  .slide29 .btn,
  .slide53 .btn {
    width: 25%;
  }
  .slide31 .btn,
  .slide37 .btn {
    width: 48%;
  }

  #gSupplements .img-button {
    padding: 10px;
    margin: 3px;
    width: 47%;
    overflow: hidden;
  }

  #gFrequently .img-button {
    padding: 10px;
    margin: 3px;
    width: 35%;
    vertical-align: middle;
  }
  #gFrequently .img-button .text {
    color: var(--text-58595b);
    font-size: 14px;
  }
  #gFrequently .img-button .text br {
    line-height: 15px;
  }

  .slide5 .img-button {
    width: 40%;
  }
  .slide6 .img-button {
    width: 40%;
  }

  .slide9 .form-control {
    font-size: 14px;
  }

  .slide10 button {
    padding: 20px;
    width: 47%;
    height: 50%;
  }

  .screen h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .screen .btn-custom-secondary {
    margin-top: 20px;
  }
  .slide11 .image-checkbox {
    padding: 5px;
    width: 25%;
    height: 18%;
    margin: 7px;
    vertical-align: middle;
  }
  .slide11 .image-checkbox div {
    font-size: 14px;
  }

  .slide13 button {
    padding: 20px;
    width: 40%;
    height: 40%;
  }
  .slide14 button {
    padding: 5px;
    width: 40%;
    height: 60%;
  }

  .slide17 .image-checkbox {
    padding: 5px;
    width: 30%;
    height: 22%;
    margin: 7px;
    vertical-align: middle;
  }
  .slide17 .image-checkbox div {
    font-size: 14px;
  }
  .slide17 .btn {
    margin-top: 50px;
  }

  .slide19 button {
    padding: 5px;
    width: 40%;
    height: 60%;
  }
  .slide22 button {
    padding: 10px;
    width: 30%;
    height: 40%;
  }
  .slide23 button {
    padding: 10px;
    width: 30%;
  }

  .slide24 .image-checkbox {
    padding: 10px;
    width: 45%;
    height: 50%;
    margin: 7px;
  }
  .slide24 .btn {
    margin-top: 70px;
  }

  .slide26 .image-checkbox {
    padding: 10px;
    width: 45%;
    height: 35%;
    margin: 7px;
  }

  .slide27 .image-checkbox {
    padding: 10px;
    width: 45%;
    height: 30%;
    margin: 7px;
  }

  .slide28 .image-checkbox {
    padding: 10px;
    width: 45%;
    height: 30%;
    margin: 7px;
  }

  .slide30 .image-checkbox {
    padding: 10px;
    width: 45%;
    height: 50%;
    margin: 7px;
  }

  .slide32 .btn {
    width: 30%;
    margin-left: 5px;
    text-align: center;
  }

  .slide35 .image-checkbox {
    padding: 10px;
    width: 45%;
    height: 25%;
    margin: 7px;
  }

  .slide37 .image-checkbox {
    padding: 5px;
    width: 46%;
    height: 35%;
    margin: 7px;
    overflow: hidden;
  }

  #gEat-fish .img-button {
    padding: 20px;
    width: 35%;
  }

  #gEat-meat .img-button {
    padding: 20px;
    width: 35%;
  }

  #gGrains .img-button {
    padding: 20px;
    width: 35%;
  }

  #gFruit-vege .img-button {
    padding: 20px;
    width: 35%;
  }

  #gDairy-products .img-button {
    padding: 20px;
    width: 35%;
  }

  #gEat-out .img-button {
    padding: 20px;
    width: 35%;
  }

  #gAlcoholic-drinks .img-button {
    padding: 5px;
    width: 47%;
    height: 45%;
    overflow: hidden;
  }

  #gCoffee .img-button {
    padding: 20px;
    width: 35%;
  }

  #gCoffee-alt .img-button {
    padding: 20px;
    width: 35%;
  }

  #gExposed-sun .img-button {
    padding: 10px;
    width: 47%;
    height: 50%;
    overflow: hidden;
  }

  #gSedentary .img-button {
    padding: 20px;
    width: 35%;
  }

  .slide50 button {
    padding: 20px;
    width: 40%;
    height: 70%;
  }

  .slide51 .image-checkbox {
    padding: 5px;
    width: 45%;
    height: 35%;
    overflow: hidden;
  }

  .slide52 .image-checkbox {
    width: 25%;
    height: 20%;
    overflow: hidden;
  }

  .slide54 .image-checkbox {
    padding: 5px;
    width: 30%;
    height: 25%;
    overflow: hidden;
  }
}

@media (max-width: 500px) {
  .services .services-item .services-meta {
    padding: 20px 0 0 0;
  }
  .title-wrapper {
    margin-bottom: 30px;
  }

  #gSupplements .img-button {
    padding: 10px;
    margin: 2px;
    width: 40%;
    height: 40%;
  }

  #gFrequently .img-button {
    padding: 20px;
    width: 50%;
  }

  .slide5 .img-button {
    width: 50%;
  }
  .slide6 .img-button {
    width: 30%;
    height: 40%;
  }

  .slide10 button {
    padding: 10px;
    width: 40%;
    height: 40%;
  }

  .slide11 .image-checkbox div {
    font-size: 13px;
  }
  .slide11 .image-checkbox {
    width: 25%;
    height: 100px;
  }
  .slide13 button {
    padding: 10px;
    width: 40%;
    height: 30%;
  }

  .slide14 button {
    padding: 5px;
    width: 40%;
    height: 30%;
  }

  .slide17 .image-checkbox {
    padding: 5px;
    width: 45%;
    height: 22%;
    margin: 7px;
    vertical-align: middle;
  }
  .slide17 .image-checkbox div {
    font-size: 13px;
  }

  .slide19 button {
    padding: 5px;
    width: 45%;
    height: 40%;
  }
  .slide17 .btn,
  .slide20 .btn,
  .slide21 .btn,
  .slide25 .btn,
  .slide26 .btn,
  .slide27 .btn,
  .slide28 .btn,
  .slide29 .btn,
  .slide31 .btn,
  .slide33 .btn,
  .slide34 .btn,
  .slide35 .btn,
  .slide37 .btn,
  .slide51 .btn,
  .slide52 .btn,
  .slide53 .btn,
  .slide54 .btn,
  .slide55 .btn {
    width: 40%;
  }

  .slide22 button {
    padding: 5px;
    width: 45%;
    height: 30%;
  }
  .slide23 button {
    padding: 5px;
    width: 40%;
    height: 60%;
  }
  .slide24 .image-checkbox {
    padding: 10px;
    width: 50%;
    height: 25%;
    margin: 7px;
  }
  .slide24 .btn {
    margin-top: 20px;
  }

  .slide26 .image-checkbox {
    padding: 10px;
    width: 70%;
    height: 18%;
    margin: 7px;
  }

  .slide27 .image-checkbox {
    height: 33%;
  }

  .slide28 .image-checkbox {
    padding: 10px;
    width: 45%;
    height: 25%;
    margin: 7px;
  }

  .slide30 .image-checkbox {
    padding: 10px;
    width: 45%;
    height: 25%;
    margin: 7px;
  }

  .slide32 .btn {
    width: 40%;
    margin-left: 5px;
    text-align: center;
  }
  .slide33 .image-checkbox {
    padding: 5px;
    width: 95%;
    height: 15%;
    margin: 7px;
  }
  .slide34 .image-checkbox {
    padding: 10px;
    width: 90%;
    height: 20%;
    margin: 7px;
  }

  .slide35 .image-checkbox {
    padding: 10px;
    width: 90%;
    height: 15%;
    margin: 7px;
  }

  #gLifestyle .img-button {
    padding: 20px;
    width: 45%;
    height: 130px;
  }
  .slide37 .image-checkbox {
    padding: 11px;
    width: 40%;
    height: 120px;
  }
  #gEat-fish .img-button {
    padding: 10px;
    width: 50%;
    height: 70px;
  }
  #gEat-meat .img-button {
    padding: 10px;
    width: 50%;
    height: 70px;
  }

  #gGrains .img-button {
    padding: 10px;
    width: 50%;
    height: 70px;
  }

  #gFruit-vege .img-button {
    padding: 10px;
    width: 50%;
    height: 60px;
  }

  #gDairy-products .img-button {
    padding: 10px;
    width: 50%;
    height: 60px;
  }

  #gEat-out .img-button {
    padding: 10px;
    width: 50%;
    height: 70px;
  }

  #gAlcoholic-drinks .img-button {
    padding: 20px;
    width: 50%;
    height: 20%;
  }

  #gCoffee .img-button {
    padding: 20px;
    width: 50%;
  }

  #gCoffee-alt .img-button {
    padding: 20px;
    width: 40%;
  }

  #gSmoke .img-button {
    padding: 20px;
    width: 50%;
  }

  #gExposed-sun .img-button {
    padding: 20px;
    width: 50%;
  }

  #gSedentary .img-button {
    padding: 20px;
    width: 40%;
    height: 80px;
  }

  .slide50 button {
    padding: 10px;
    width: 40%;
    height: 30%;
  }

  .slide51 .image-checkbox {
    padding: 5px;
    width: 45%;
    height: 110px;
    margin: 7px;
    overflow: hidden;
  }

  .slide52 .image-checkbox {
    width: 29%;
    height: 20%;
    overflow: hidden;
  }

  .slide54 .image-checkbox {
    padding: 3px;
    width: 45%;
    height: 65px;
    margin: 7px;
    overflow: hidden;
  }

  .slide55 .image-checkbox {
    padding: 7px;
    width: 45%;
    height: 65px;
    overflow: hidden;
  }
}

@media (max-width: 576px) {
  .home-banner .home-text-wrapper {
    text-shadow: none;
  }
  .home-banner {
    background: url(https://riise-media.s3.amazonaws.com/resources/images/homepage-banner-mobile.webp);
    background-size: 576px, 500px;
    height: 500px;
  }
}

/***** User Area **************************/

.user-sidebar {
  padding: 0px;
  margin-top: 0px;
}
.user-sidebar a {
  font-size: 18px;
  color: var(--text-58595b);
}
.user-sidebar i {
  margin-right: 15px;
}
.user-sidebar div {
  padding: 10px 15px;
  margin-left: 25px;
  margin-right: 25px;
}
.user-sidebar div:hover {
  background-color: var(--bg-efeff0);
}
.user-sidebar a:hover {
  color: black;
}
.user-sidebar .checked {
  background-color: var(--bg-efeff0);
}
.user-sidebar .checked a {
  color: black;
}

.user-inner .form-control {
  padding: 14px 30px 14px 30px;
  border-radius: 25px;
  font-size: 16px;
}

.user-inner label {
  font-size: 18px;
  margin-left: 15px;
  margin-bottom: 10px;
}

/***** partner page***********/

.partner .top-head {
  font-size: 36px;
}
.partner-img {
  border-radius: 15px;
}
.mtrow {
  margin-top: 100px !important;
}

.breadcrumbs-wrapper-partner {
  padding: 20px 0;
  background: linear-gradient(var(--tertiary-text), var(--tertiary-text));
  background-size: cover;
  background-position: center;
  color: white;
}

.breadcrumbs-wrapper-partner::after {
  background: #000;
  opacity: 0.5;
}

.progressbar-label {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
}

.custom-progress-bar .progress-bar {
  background-color: var(--secondary);
}

/**********************/

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--secondary);
  color: #fff;
  padding: 10px;
  text-align: center;
  z-index: 100;
}

.cookie-banner button {
  background: #fff;
  color: #333;
  padding: 5px 10px;
  cursor: pointer;
}

.video-bg .title2 {
  font-family: var(--secondary-font);
  font-size: 40px;
  color: white;
  font-weight: 600;
}

.video-bg h6 {
  color: white;
  font-weight: normal;
  line-height: 1.6;
}

.video-bg {
  position: relative;
  overflow: hidden;
  background-color: black;
}

.video-bg .container {
  z-index: 2;
  position: relative;
}

.video-bg video {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /*  object-fit is not supported on IE  */
  object-fit: cover;
  opacity: 0.5;
}

.faq .row > div:first-child {
  background-color: #fff;
  padding: 40px 90px 50px 90px;
}

.faq .title2 {
  color: var(--tertiary-text);
}
.faq p {
  color: var(--tertiary-text);
}

.faq-home .accordion .accordion-item {
  border-radius: 50px;
  margin-bottom: 15px;
  border: 1px solid #003b4d !important;
}

.faq .row > div:last-child {
  background-color: #fff;
}

.faq .accordion .accordion-item .accordion-header button {
  color: var(--tertiary-text);
}

.faq .accordion .accordion-item {
  color: var(--tertiary-text);
}

.faq i {
  font-size: 22px;
  margin-right: 20px;
}

.whyriise tr td div {
  text-wrap: wrap;
}

.experts .img-style {
  position: relative;
}
.experts .img-style img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 0;
  padding: 10px;
  margin-bottom: 20px;
}

.advantages {
  padding-top: 0;
  background: var(--tertiary-text);
}

.advantages-box h1 {
  line-height: 12px;
}

.advantages-box div {
  margin-top: 12px;
}

.advantages-box h1,
.advantages-box p,
.advantages-box ul {
  color: #fff !important;
}
.advantages .advantages-box ul li {
  text-align: left;
}

.advantages .advantages-box ul li:after {
  background-color: #fff;
}
.advantages .advantages-box {
  padding-top: 0;
  background: none;
}

.section-padding {
  padding-bottom: 100px;
  padding-top: 30px;
}

.difference-section {
  padding-top: 40px;
  padding-bottom: 0;
}

.section-padding-exports {
  padding-top: 75px;
  padding-bottom: 75px;
}

@media (max-width: 1200px) {
  .mobile-sidebar li {
    padding: 0 0;
    margin-bottom: 0;
    border-bottom: 1px solid #ececec;
    line-height: 30px;
  }

  .m-lang {
    margin-top: 15px;
  }

  .m-lang a {
    color: var(--secondary-text);
    font-weight: 600;
    font-size: 15px;
  }

  .m-lang i {
    margin-left: 5px;
    font-size: 18px;
  }

  .mobile-sidebar li:first-child,
  .mobile-sidebar li:last-child {
    border-bottom: none !important;
  }

  .experts {
    padding-bottom: 10px;
  }
  .experts h3 {
    font-size: 20px;
  }
  .experts .img-style img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 0;
    padding: 10px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .experts {
    padding-top: 70px;
  }
  .experts-wrapper {
    bottom: -293px;
  }
}

@media (max-width: 576px) {
  .home-banner .home-text-wrapper {
    text-shadow: none;
  }
  .experts {
    padding-top: 30px;
  }

  .experts-wrapper {
    bottom: -408px;
  }

  .experts .img-style img {
    width: 175px;
    height: 175px;
    margin-bottom: 0;
  }
  .experts h3 {
    font-size: 28px;
  }

  .experts-wrapper {
    bottom: -420px;
  }
  .experts-wrapper h3 {
    font-size: 16px;
  }

  .img-style h3:nth-of-type(2) {
    font-size: 16px;
  }

  .faq .row > div:first-child {
    padding: 20px;
  }

  .section-padding {
    padding-bottom: 40px;
  }

  .steps .section-padding img {
    width: 250px;
  }

  .steps div.col-lg-3 {
    text-align: center;
  }

  .inner-page h4 {
    font-size: 18px;
  }
}

/* .faq-custom .accordion-header{font-size: 26px;font-weight: 600; color: var(--text-58595b); padding: 10px; margin-left:10px;}
.faq-custom h5{color: var(--text-6d6e71);font-weight: normal;}
.faq-custom .accordion .accordion-item{border-radius: 50px;margin-bottom: 15px;border:1px solid var(--bg-d1d3d4) !important;}
.faq-custom .accordion .accordion-item .accordion-header button{border-radius: 50px;}
.faq-custom .accordion .accordion-item .accordion-header button:focus{box-shadow: none;}

.faq-custom .accordion .accordion-button img {margin-right: 15px;}
.faq-custom .accordion .accordion-button {font-size: 22px;text-transform: uppercase;color: var(--text-6d6e71);    padding: 10px 20px;}

.faq-custom .accordion .accordion-button:not(.collapsed) {
  color: var(--text-6d6e71);
  background-color: transparent;
  box-shadow: unset;
}
.faq-custom .accordion .accordion-button-arrow {

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-position: 98% !important;

  transition: transform .2s ease-in-out;

}

.faq-custom .accordion .accordion-button:not(.collapsed)::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23005d67'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  color: var(--text-6d6e71);
}
.faq-custom p {
    color: var(--text-58595b);
    padding: 20px;
}
.faq-custom .accordion .accordion-item {    
    width: 100%;
    max-width: 100%;
}
 */
